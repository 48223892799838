import React, {Component} from "react";
import {
    Button,
    Card,
    Container,
    Divider,
    Grid,
    GridColumn,
    GridRow,
    Header, Image
} from "semantic-ui-react";
import ProductBody, {CardTitle, CSFooter} from "./product-purchase-form-view-lib";
import {CurrencyButtonCNY} from "../../credit-purchase/currency-buttons/currency-button-cny";
import {CurrencyButtonTWD} from "../../credit-purchase/currency-buttons/currency-button-twd";
import {CurrencyButtonUSD} from "../../credit-purchase/currency-buttons/currency-button-usd";
import {CurrencyButtonHKD} from "../../credit-purchase/currency-buttons/currency-button-hkd";
import {CurrencyButtonMYR} from "../../credit-purchase/currency-buttons/currency-button-myr";
import AndroidComm from "../../../controllers/utilities/unity-android-communication";
import icon from "../../../images/jgglogo_202102.svg";
import {environmentMode, setAdItem} from "../../lib/ad-manage-api-lib";
import iconGg from "../../../images/gg-logo.png";
import confrim1 from '../../../images/main/confrim1.png';
import confrim2 from '../../../images/main/confrim2.png';
import confrim from '../../../images/main/touptop.png';
import Background from "../../../images/btn-001.png";

import cookie from 'react-cookies';
import box1 from "../../../images/main/box1.png";
import box2 from "../../../images/main/box2.png";
import boxd1 from "../../../images/main/boxd1.png";
import boxd2 from "../../../images/main/boxd2.png";
import hint1 from "../../../images/main/hint1.png";
import hint2 from "../../../images/main/hint2.png";

export default (props) => {
    const {
        transaction,
        onUpTop,
        onBuy,
        onReturn,
        onCancel,
        onLastReport,
        hasEnough,
        eventMessage,
        bonusMessage,
        mode,
        userId,
        newgameId, newattributionId, newaccessToken, newtransactionId
    } = props;
    let platform = cookie.load("_platform");
    if (!platform){
        platform = "h5";
    }
    console.log(transaction);
    if (transaction.total === 0){
        let returnName = "返回";
        if (transaction.products[0].itemName.indexOf("拍面禮包") !== -1){
            returnName = "返回遊戲";
        }
        if (mode === "PORTRAIT") {
            return (
                <React.Fragment>
                    <div
                      style={{
                        backgroundImage: `url(${box2})` , backgroundSize:'100% 100%',
                        padding:"2rem",
                      }}>
                        <Card fluid style={{
                            boxShadow: '0 1px 3px 0 transparent, 0 0 0 1px transparent',
                            backgroundColor:"transparent",
                        }}>
                            {/* <CardTitle total={transaction.total} onCancel={onCancel} onLastReport={onLastReport}/> */}
                            <ProductBody transaction={transaction} onLastReport={onLastReport} hasEnough={hasEnough} mode={mode}/>
                        </Card>
                        {/* {<Button style={{
                            backgroundColor: "transparent",
                            marginTop: 1, height: 40, fontSize: '1.1em', backgroundImage: `url(${hasEnough?confrim2:confrim1})` , backgroundSize:'100% 100%',textShadow:"1px 1px #000,-1px -1px #000,1px -1px #000,-1px 1px #000" }} disabled={!hasEnough} fluid
                                 color={"green"}
            onClick={onReturn}>
                {returnName}
                        </Button>} */}
                    </div>
                    {/* <Divider style={{
                        margin:"0.5em 0",
                    borderTop: "2px solid rgba(34,36,38,.15)",
                    borderBottom: "2px solid rgba(255,255,255,.1)"
                }}/> */}
                {(platform !== "h5") &&(
                    <div style={{padding:"1rem"}}>
                <Grid  style={{
                    backgroundImage: `url(${hasEnough?boxd2:boxd1})` , backgroundSize:'100% 100%',
                }}><Grid.Row>
                <Grid.Column style={{marginTop: "0vh",marginBottom: "0vh"}}>
                    
                    <Header size={"medium"} style={{margin:"1rem", color: '#fff'}}>&nbsp;</Header>
                <Container fluid style={{padding: "1rem"}}>
                    <CreditPurchase width={"100%"}/>
                </Container>
                {/* <Header size={"medium"} style={{margin:"0",  color: '#fff'}}>&nbsp;</Header> */}
                </Grid.Column>
</Grid.Row></Grid></div>)}
                    {(platform === "h5") &&(<>                        
                        <Container fluid>
                        <Image style={{width:"100%"
                        }} src={confrim} onClick={onUpTop}/>

                            {/* <Button style={{
                            marginTop: 1, height: 60,backgroundColor:"transparent",
                    fontWeight: 'bolder',
                    fontSize: '1.48em',
                    color: '#fff',
                    textShadow:"1px 1px #000,-1px -1px #000,1px -1px #000,-1px 1px #000",
                    backgroundImage: `url(${confrim})` , backgroundSize:'100% 100%',
                }}  compact 
                onClick={onUpTop} fluid
                >&nbsp;</Button> */}
                </Container>

                    </>)}
                    <Header size={"medium"} style={{marginTop: 4, marginBottom: 0, color: '#fff'}}>{eventMessage}</Header>
                    
                </React.Fragment>
            );
        }else{
            // 横屏
            let isIOS = "-1";
            if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
                // 暂时去掉ios两板功能
                isIOS = "1";
            }else{
                isIOS = "0";
            }
            let showTextAd = {
                display: ''
            };
            if (environmentMode === "SANDBOX") showTextAd =  {
                display: 'none'
            };
            // let iconJgg = icon;
            // if (environmentMode === "SANDBOX") iconJgg = iconGg;
            return (
                <React.Fragment>
                    <div>
                    <div style={{width:"49%",float:"left"}}>                        
                    <div fluid style={{
                        backgroundImage: `url(${box1})` , backgroundSize:'100% 100%',
                        padding:"3.3vh",
                        height:"69.3vh",
                    }}>
                        <Card fluid style={{
                            boxShadow: '0 1px 3px 0 transparent, 0 0 0 1px transparent',
                            margin:"0.5rem 0",
                            backgroundColor:"transparent"
                        }}>
                            {/* <CardTitle total={transaction.total} onCancel={onCancel} onLastReport={onLastReport}/> */}
                            <ProductBody transaction={transaction} onLastReport={onLastReport} hasEnough={hasEnough}  mode={mode}/>
                        </Card>
                        {/* {<Button style={{
                            backgroundColor: "transparent",
                            marginTop: 1, height: 60, fontSize: '1.1em', backgroundImage: `url(${hasEnough?confrim2:confrim1})` , backgroundSize:'100% 100%',textShadow:"1px 1px #000,-1px -1px #000,1px -1px #000,-1px 1px #000" }} disabled={!hasEnough} fluid
                                 color={"green"}
            onClick={onReturn}>
                {returnName}
                        </Button>} */}
                    </div>
                    </div>
                    
                    {(platform !== "h5") &&(<>
                        <div style={{width:"49%",float:"right",
                backgroundImage: `url(${hasEnough?boxd2:boxd1})` , backgroundSize:'100% 100%',
                height:"69.3vh",
                paddingTop: "13.2vh"
                }}>
                    {/* <Header size={"medium"} style={{margin:"0.5em 0", marginTop: 4, color: '#fff'}}>—
                        請選擇您的充值貨幣 —</Header> */}
                    <Container fluid style={{width:"90%"}}>
                        <CreditPurchase width={"90%"}/>
                    </Container></div></>)}
                    {(platform === "h5") &&(<>    
                        <div style={{width:"49%",float:"right",
                height:"69.3vh",
                paddingTop: "13.2vh"
                }}>                    
                        <Container fluid>
                        <Image style={{width:"100%"
                        }} src={confrim} onClick={onUpTop}/>
                            {/* <Button style={{
                            marginTop: 1, height: "10vh",backgroundColor:"transparent",
                    fontWeight: 'bolder',
                    fontSize: '1.48em',
                    color: '#fff',
                    textShadow:"1px 1px #000,-1px -1px #000,1px -1px #000,-1px 1px #000",
                    backgroundImage: `url(${confrim})` , backgroundSize:'100% 100%',
                }}  compact 
                onClick={onUpTop} fluid
                >&nbsp;</Button> */}
                </Container>
</div>
                    </>)}
                    <Header size={"medium"} style={{marginTop: 4, marginBottom: 0, color: '#fff'}}>{eventMessage}</Header>
                    
                    
                        </div>

                </React.Fragment>
            );
        }
    }else{
        if (mode === "PORTRAIT") {
            return (
                <React.Fragment>
                    <div fluid style={{
                        backgroundImage: `url(${box1})` , backgroundSize:'100% 100%',
                        padding:"1rem",
                    }}>
                        <Card fluid style={{
                            boxShadow: '0 1px 3px 0 transparent, 0 0 0 1px transparent',
                            backgroundColor:"transparent",
                            paddingTop:"0.5rem"
                        }}>
                            {/* <CardTitle onCancel={onCancel} onLastReport={onLastReport}/> */}
                            <ProductBody transaction={transaction} onLastReport={onLastReport} hasEnough={hasEnough} mode={mode}/>
                            {/* <CSFooter transaction={transaction}/> */}
                        </Card>
                        {
                            <Image style={{width:"100%"
                        }} src={hasEnough?confrim2:confrim1} onClick={onBuy}/>
                        //     <Button style={{
                        //     backgroundColor: "transparent",
                        //     marginTop: 5, height: 60, fontSize: '1.68em', backgroundImage: `url(${hasEnough?confrim2:confrim1})` , backgroundSize:'100% 100%',textShadow:"1px 1px #000,-1px -1px #000,1px -1px #000,-1px 1px #000" }} 
                        //     // disabled={!hasEnough}
                        //      fluid
                        //         //  color={hasEnough ? "green" : "grey"}
                                
                        //          onClick={onBuy}>
                        //     &nbsp;
                        // </Button>
                    }

                        <div size={"tiny"}
                         style={{
                             display: 'flex',
                             justifyContent: 'space-between',
                             alignItems: 'center',
                             padding: '7px',
                             color: '#333',
                             marginBottom: '5px'
                         }}>
                             {(bonusMessage.indexOf("餘額不足") !== -1) &&(<div fluid style={{marginTop:"1vh", marginBottom:"1vh"}}><Image fluid src={hint1}/></div>)}
                             {(bonusMessage.indexOf("餘額不足") === -1) &&(<div fluid style={{marginTop:"1vh", marginBottom:"1vh"}}><Image fluid src={hint2}/></div>)}
                             </div>
                    </div>
                    {/* <Divider style={{
                        margin:"0.5em 0",
                    borderTop: "2px solid rgba(34,36,38,.15)",
                    borderBottom: "2px solid rgba(255,255,255,.1)"
                }}/> */}
                    {(platform !== "h5") &&(
                        <div style={{padding:"1rem"}}>
                    <Grid  style={{
                        backgroundImage: `url(${hasEnough?boxd2:boxd1})` , backgroundSize:'100% 100%',
                    }}><Grid.Row>
                    <Grid.Column style={{marginTop: "0vh",marginBottom: "0vh"}}>
                        
                        <Header size={"medium"} style={{margin:"0.5rem", color: '#fff'}}>&nbsp;</Header>
                    <Container fluid style={{padding: "1rem"}}>
                        <CreditPurchase width={"100%"}/>
                    </Container>
                    {/* <Header size={"medium"} style={{margin:"0rem",  color: '#fff'}}>&nbsp;</Header> */}
                    </Grid.Column>
    </Grid.Row></Grid></div>)}
                    {(platform === "h5") &&(<>                        
                        <Container fluid>
                        <Image style={{width:"100%"
                        }} src={confrim} onClick={onUpTop}/>
                            {/* <Button style={{
                            marginTop: 1, height: 60,
                            backgroundColor:"transparent",
                    fontWeight: 'bolder',
                    fontSize: '1.48em',
                    color: '#fff',
                    textShadow:"1px 1px #000,-1px -1px #000,1px -1px #000,-1px 1px #000",
                    backgroundImage: `url(${confrim})` , backgroundSize:'100% 100%',
                }}  compact 
                onClick={onUpTop} fluid
                >&nbsp;</Button> */}
                </Container>

                    </>)}
                    <Header size={"medium"} style={{marginTop: 4, marginBottom: 0, color: '#fff'}}>{eventMessage}</Header>
                    
                </React.Fragment>
            );
        }else{
            // 横屏
            let isIOS = "-1";
            if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
                // 暂时去掉ios两板功能
                isIOS = "1";
            }else{
                isIOS = "0";
            }
            let showTextAd = {
                display: ''
            };
            if (environmentMode === "SANDBOX") showTextAd =  {
                display: 'none'
            };
            // let iconJgg = icon;
            // if (environmentMode === "SANDBOX") iconJgg = iconGg;
            return (
                <React.Fragment>
                    <div>
                    <div style={{width:"49%",float:"left"}}>                        
                    <div 
                    fluid style={{
                        backgroundImage: `url(${box1})` , backgroundSize:'100% 100%',
                        padding:"6vh",
                        height:"69.3vh",
                    }}
                    >
                        <Card fluid style={{
                            boxShadow: '0 1px 3px 0 transparent, 0 0 0 1px transparent',
                            backgroundColor:"transparent"
                        }}>
                            {/* <CardTitle onCancel={onCancel} onLastReport={onLastReport}/> */}
                            <ProductBody transaction={transaction} onLastReport={onLastReport} hasEnough={hasEnough} mode={mode}/>
                            {/* <CSFooter transaction={transaction}/> */}
                        </Card>
                        {<Image style={{width:"100%"
                        }} src={hasEnough?confrim2:confrim1} onClick={onBuy}/>
                        // <Button style={{
                        //     backgroundColor: "transparent",
                        //     marginTop: 5, height: "10vh", fontSize: '1.68em', backgroundImage: `url(${hasEnough?confrim2:confrim1})` , backgroundSize:'100% 100%',textShadow:"1px 1px #000,-1px -1px #000,1px -1px #000,-1px 1px #000" }} disabled={!hasEnough} fluid
                        //         //  color={hasEnough ? "green" : "grey"}
                        //          onClick={onBuy}>
                        //     &nbsp;
                        // </Button>
                        }

                        <div size={"tiny"}
                         style={{
                             display: 'flex',
                             justifyContent: 'space-between',
                             alignItems: 'center',
                             padding: '7px',
                             color: '#333',
                             marginBottom: '5px'
                         }}>
                             {(bonusMessage.indexOf("餘額不足") !== -1) &&(<Image style={{
                            marginTop: "-1vh",
                            marginBottom: "1vh",
                            // height: "1em"
                        }} centered src={hint1}/>)}
                             {(bonusMessage.indexOf("餘額不足") === -1) &&(<Image style={{
                            marginTop: "-1vh",
                            marginBottom: "1vh",
                            // height: "1em"
                        }} centered src={hint2}/>)}

                             </div>
                    </div>
                    </div>
                    
                    {(platform !== "h5") &&(<>
                        <div style={{width:"49%",float:"right",
                backgroundImage: `url(${hasEnough?boxd2:boxd1})` , backgroundSize:'100% 100%',
                height:"69.3vh",
                paddingTop: "13.2vh"
                }}>
                    {/* <Header size={"medium"} style={{margin:"0.5em 0", marginTop: 4, color: '#fff'}}>—
                        請選擇您的充值貨幣 —</Header> */}
                    <Container fluid style={{width:"90%"}}>
                        <CreditPurchase width={"90%"}/>
                    </Container></div></>)}
                    {(platform === "h5") &&(<>                        
                        <div style={{width:"49%",float:"right",
                height:"25rem",
                paddingTop: "4rem"
                }}>
                        <Container fluid>
                        <Image style={{width:"100%"
                        }} src={confrim} onClick={onUpTop}/>
                            {/* <Button style={{
                            marginTop: 1, height: "10vh",backgroundColor:"transparent",
                    fontWeight: 'bolder',
                    fontSize: '1.48em',
                    color: '#fff',
                    textShadow:"1px 1px #000,-1px -1px #000,1px -1px #000,-1px 1px #000",
                    backgroundImage: `url(${confrim})` , backgroundSize:'100% 100%',
                }}  compact 
                onClick={onUpTop} fluid
                >&nbsp;</Button> */}
                </Container>
</div>
                    </>)}
                    <Header size={"medium"} style={{marginTop: 4, marginBottom: 0, color: '#fff'}}>{eventMessage}</Header>
                    
                    
                        </div>

                </React.Fragment>
            );
        }
    }
};

async function showAd(sType, Url, eventId, newgameId, newattributionId, newaccessToken, newtransactionId){
    // window.location.href = Url;
    // window.open(Url + "&r1=download", 'newwindow'+ sType);
    // window.location.href = Url + "&r1=download";
    // console.log(navigator.userAgent);
    // 暂时去掉ios两板功能
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        // Url = "";
    }
    if (Url !== ""){
        const url = Url + "&rr1=download";
        //const uuid = Buffer.from(url).toString('base64');
        const uuid = encodeURIComponent(Buffer.from(url).toString('base64'));
        console.log(uuid);
        // gameId=06ea3f8f-04fa-4b21-800d-3c2220f11eb2&attributionId=9&accessToken=aafb7f27-da9d-4198-a301-0dd8f73761d2&transactionId=e2815246-7db3-4a41-95e4-e14bb85ee2cf
        let newurl =  `/gotoad-return-purchase/?gameId=${newgameId}&attributionId=${newattributionId}&accessToken=${newaccessToken}&transactionId=${newtransactionId}&uuid=${uuid}&r1=download`;
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
            newurl =  `/gotoadios/?gameId=${newgameId}&attributionId=${newattributionId}&accessToken=${newaccessToken}&transactionId=${newtransactionId}&uuid=${uuid}&r1=download`;
        }
        console.log(newurl);
        window.location.href = newurl;
        // if (eventId)
        //     setAdItem(this.state.newaccessToken,eventId,sType);
    }
}

class CreditPurchase extends Component {
    render() {
        const {width} = this.props;

        return (
            <div style={{width:`${width}`,
            position:"relative",
            margin: "0 auto",
            }}>
            <Grid columns={3}
                  style={{
                    margin: "auto",
                      width:`${width}`,
                      backgroundColor: 'transparent',
                      color: '#ff1700',
                      borderRadius: '0.5rem',
                      // boxShadow: "inset 0px -3px 30px -3px #FFF",
                      
                  }}
            >
                <GridRow style={{paddingBottom: "0rem"}}>
                    <GridColumn>
                        <CurrencyButtonCNY/>
                    </GridColumn>
                    <GridColumn>
                        <CurrencyButtonTWD/>
                    </GridColumn>
                    <GridColumn>
                        <CurrencyButtonUSD/>
                    </GridColumn>
                </GridRow>
                <GridRow style={{paddingBottom: "2rem"}}>
                    <GridColumn>
                        <CurrencyButtonHKD/>
                    </GridColumn>
                    <GridColumn>
                        <CurrencyButtonMYR/>
                    </GridColumn>
                </GridRow>
            </Grid>
            </div>
        );
    }
}

