import React, {Component} from "react";
import {CurrencyButtonBase} from "../../lib/currency-button-base";
import currencyBtn from "./images/currency-btn-hkd.png";

export class CurrencyButtonHKD extends Component {
    render() {
        return (
            <CurrencyButtonBase src={currencyBtn} currency={"HKD"}/>
        );
    }
}