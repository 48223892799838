import React, {Component} from "react";
import {Button, Card, CardContent, Form, Message} from "semantic-ui-react";
import AndroidComm from "../../../controllers/utilities/unity-android-communication";
import box2 from "../../../images/main/box2.png";

export class ProductPurchaseFormNotReady extends Component {
    render() {
        return (
                      <div 
                      style={{
                        backgroundImage: `url(${box2})` , backgroundSize:'100% 100%',
                        padding:"2rem",
                      }}>
            <Form size={"small"} loading={this.props.loading} success={this.props.success}
                  error={this.props.error}>
                <Card fluid style={{
                            boxShadow: '0 1px 3px 0 transparent, 0 0 0 1px transparent',
                            backgroundColor:"transparent",
                        }}>
                    <CardContent>
                        <Message
                            error
                            header='Error'
                            content={this.props.message}
                        />
                        <Button color='green' fluid size='large' onClick={() => {
                            AndroidComm.sendRawMessage({
                                success: false,
                                message: this.props.message
                            });
                        }}>
                            回到遊戲
                        </Button>
                    </CardContent>
                </Card>
                </Form>
                </div>
            
        );
    }
}