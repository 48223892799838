import React, {Component, Fragment} from "react";
import {Button, CardContent, CardHeader, Grid, Header, Image} from "semantic-ui-react";
import {Link} from "gatsby";
import {getProductTotal} from "../../../controllers/utilities/transaction-util";
import icon from "../../../images/report.png";
import Background from '../../../images/btn-002.png';
import coin from "../../../images/main/coin.png";
import btn1 from "../../../images/main/btn1.png";
import btn2 from "../../../images/main/btn2.png";
import btn3 from "../../../images/main/btn3.png";
import {navigate} from "gatsby";

export default class ProductBody extends Component {
    render() {
        const {transaction, onLastReport, hasEnough, mode} = this.props;
        const {products, gameId, balance, total, creditcount, productcount} = transaction;
        return (
            <Fragment>
                <MyBalance balance={balance} onLastReport={onLastReport} hasEnough={hasEnough} total={total} mode={mode} creditcount={creditcount} productcount={productcount}/>
                {(total > 0) && (<div style={{marginTop:"-15px"}}><ProductList gameName={gameId} products={products}/></div>)}
            </Fragment>
        );
    }
};

export class CardTitle extends Component {
    render() {
        const {total, onCancel, onLastReport} = this.props;
        console.log("total = " + total);
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        let wh = width;
        if (height < wh) wh = height;
        let fontSize1 = "1.2em";
        if (wh < 375) {
            fontSize1 = "0.9em";
        }
        return (
            <CardContent style={{
                backgroundColor: "#8c0702",
                height: 40
            }}>
                {(total !== 0)&& (<Button style={{
                    marginTop: -5,
                    color: '#fff',
                    backgroundColor: '#570703'
                }} compact size={"tiny"} floated={"right"}
                        onClick={onCancel}>取消</Button>)}
                <Button style={{
                    marginTop: -5,
                    color: '#fff',
                    backgroundColor: '#570703',
                    paddingTop:"0.2em",
                    paddingBottom:"0.2em",
                    paddingLeft: "1.2em",
                    paddingRight: "1.2em"
                }} floated={"right"}
                        onClick={onLastReport}>
                    <Image style={{
                        width:"1.45rem",
                        height:"1.45rem",
                        color:"#fff"
                    }} centered src={icon}/>
                </Button>
                <CardHeader style={{color: "white",fontSize:`${fontSize1}`}} textAlign={"left"}>
                {(total === 0) ? "錢包餘額" : "你所購買的物品"}
                </CardHeader>
            </CardContent>
        );
    }
}

export class MyBalance extends Component {
    render() {
        const {balance, onLastReport, hasEnough, total, mode, creditcount, productcount} = this.props;

        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        let wh = width;
        if (height < wh) wh = height;
        let fontSize1 = "1em";
        if (wh < 375) {
            fontSize1 = "0.7em";
        }
        return (
            <div style={{
                paddingTop: 10,
            }}>
                        <CardContent style={{
                margin: 0,
                marginBottom: 0,
                paddingTop: 5,
                paddingBottom: 0,
                width:"100%"
            }}>
                <CardHeader style={{fontSize:`${fontSize1}`, width:"100%"}}>
                    <Header floated={"left"} style={{textAlign:"left", width:"42%", marginTop: "0px", marginLeft:"2%"}} size="large">
                    <Image style={{
                        width:"1.45rem",
                        height:"1.45rem",
                        color:"#fff",
                        marginTop: "-5px"
                    }} centered src={coin}/>錢包餘額</Header>
                    <Header color={"green"} floated={"right"}
                             style={{marginRight: 10, width:"42%", marginTop: "-5px"}}>
                    
                    <Image style={{width:"100%"
                    }} src={btn1} onClick={onLastReport}/>

{/* <Button style={{
                    fontWeight: 'bolder',
                    color: '#fff',
                    backgroundColor:"transparent",
                    height:`${mode==="PORTRAIT"?"3em":"10vh"}`,
                    // minHeight: "60px",
                    backgroundImage: `url(${btn1})` , backgroundSize:'100% 100%'
                }} fluid compact as={Link}
                onClick={onLastReport}>&nbsp;</Button> */}
                             </Header>
                </CardHeader>
            </CardContent>
            <div style={{clear:"both"}}></div>
            <CardContent style={{
                marginTop: -5,
                marginBottom: 0,
                paddingTop: 5,
                paddingBottom: 5,
                width:"100%"
            }}>
                <CardHeader style={{fontSize:`${fontSize1}`, width:"100%"}}>
                    <Header color={"green"} floated={"left"} style={{textAlign:"left", width:"40%",marginLeft:"2%", marginTop:"-5%", whiteSpace: "nowrap"}} size="large">幣 {balance}</Header>
                    <Header color={"green"} floated={"right"}
                             style={{marginRight: 10, width:"42%", marginTop: "-5px"}} >
{(hasEnough && total >0) && (productcount === 0) && (
    <Image style={{width:"100%",zIndex: "999"
                    }} src={btn3} onClick={()=>{
                        console.log("ccc");
                            navigate("/sdk-teaching2");
                    }}/>)
}

{((total === 0) || (!hasEnough)) && (creditcount === 0) && (
    <Image style={{width:"100%"
                    }} src={btn2} onClick={()=>{
                        console.log("ddd");
                            navigate("/sdk-teaching1"); 
                    }}/>)
}


{/* <Button style={{
                    fontWeight: 'bolder',
                    color: '#fff',
                    backgroundColor:"transparent",
                    height:`${mode==="PORTRAIT"?"3em":"10vh"}`,
                    backgroundImage: `url(${(hasEnough && total >0)?btn3:btn2})` , backgroundSize:'100% 100%'
                }} fluid compact  as={Link}
                onClick={async ()=>{
                    console.log("123");
                    if (hasEnough && total >0){
                        navigate("/sdk-teaching2");
                    }else{
                        navigate("/sdk-teaching1"); 
                    }
                }}
                >&nbsp;</Button> */}
                             </Header>
                </CardHeader>
            </CardContent>

            </div>
        );
    }
}

export class ProductList extends Component {
    render() {
        const {products, gameName} = this.props;
        return products.map(product => <ProductDetails key={product.itemId}
                                                       gameName={gameName} {...product}/>);
    }
}

export class ProductDetails extends Component {
    render() {
        const productDescStyle = {
            margin: 0,
            padding: 0,
            height:"20",
            whiteSpace: "nowrap"
        };
        const {
            imageUrl,
            itemName,
            quantity,
            unitPrice
        } = this.props;
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        let wh = width;
        if (height < wh) wh = height;
        let fontSize1 = "1em";
        if (wh < 375) {
            fontSize1 = "0.7em";
        }
        return (
            <div style={{paddingLeft: "15px", paddingTop:"15px"}}>
                <Grid>
                    <Grid.Column style={{
                        padding: 5,
                        verticalAlign:"middle"
                    }} width={3}>
                        <Image fluid src={imageUrl}/>
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <Grid style={{verticalAlign: "top"}}>
                            <Grid.Row style={{fontSize:`${fontSize1}`, height:"30px", marginTop:"-10px"}}>
                                <Grid.Column width={10} textAlign={"left"} style={{height:"30px"}}>
                                    <Header style={productDescStyle}
                                            size={"medium"}>{itemName} x {quantity}</Header>
                                </Grid.Column>
                                </Grid.Row>
                                <Grid.Row style={{fontSize:`${fontSize1}`, height:"30px", marginTop:"-10px"}}>
                                <Grid.Column width={6} textAlign={"left"} style={{height:"30px"}}>
                                    <Header style={productDescStyle} color={"green"}
                                            size={"medium"}>幣 {unitPrice * quantity}</Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export class CSFooter extends Component {
    render() {
        const {transaction} = this.props;
        return (
            <CardContent style={{
                backgroundColor: "#8c0702",
                height: 55,
                marginTop: 0
            }}>
                <Button style={{
                    border: '1px solid black',
                    fontWeight: 'bolder',
                    color: '#fff',
                    textShadow:"1px 1px #000,-1px -1px #000,1px -1px #000,-1px 1px #000",
                    backgroundImage: `url(${Background})` , backgroundSize:'100% 100%'
                }} fluid compact as={Link}
                        to={`/sdk-callback`}>帳號管理(內有送幣福利)</Button>
            </CardContent>
        );
    }
}

export function canBuyWithJGG(transaction) {
    const {balance} = transaction;
    const total = getProductTotal(transaction);
    return balance >= total;
}