import React, { Component } from "react";
import { Grid, Image } from "semantic-ui-react";
import ProductPurchaseForm from "../components/forms/product-purchase-form/product-purchase-form";
import icon from "../images/jgglogo_202102.svg"
import iconGg from "../images/gg-logo.svg";
import { environmentMode } from "../components/lib/ad-manage-api-lib";
import { connect } from "react-redux";
import { rejectIfAnyNull } from "../controllers/utilities/env-checks";
import { getAdPItem, setAdItem, ImgVideoSmall } from "../components/lib/ad-manage-api-lib";
import { formatQuery } from "../controllers/utilities/string-utils";
import cookie from 'react-cookies';
import { navigate } from "../../.cache/gatsby-browser-entry";
import { AdPSwipetoslide } from "../components/react-slick/ad-p-swipetoslide";
import "../styles/bg.css";
import KaisakuApi from "../controllers/kaisaku-api";
import { MsgModal } from "../lib/msgmodal";
import { showbox } from "../lib/showmsgbox";
import { getUA } from "react-device-detect";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import NavigationBar from "../components/forms/navigation-bar";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            eventId: '',
            newaccessToken: '',
            newgameId: '',
            newtransactionId: '',
            newattributionId: '',
            platform: '',
            isIOS: "-1",
            adItem1: [],
            adItemTop: [],
            open: false,
            isCnIP: false
        };
        console.log(this.props.session);
        let transactionId = this.props.transactionId;
        if (!transactionId) {
            let newAccessToken = cookie.load("_accessToken");
            let newGameId = cookie.load("_gameId");
            let newAttributionId = cookie.load("_attributionId");
            let pendingTransactionId = cookie.load("_transactionId");
            let platform = cookie.load("_platform");
            if ((newAccessToken !== undefined) && (newGameId !== undefined) && (newAccessToken !== null) && (newGameId !== null)) {
                let platform = cookie.load("_platform");
                const url = `/confirm-purchase/?gameId=${newGameId}&attributionId=${newAttributionId}&transactionId=${pendingTransactionId}&accessToken=${newAccessToken}&platform=${platform}`;
                // console.log(url);
                window.location.href = url;
            }

        } else {
            rejectIfAnyNull(this.props.session, transactionId);
        }
        const q = this.props.location.search;
        if (q) {
            console.log("q = " + q);
            cookie.save("_q", q, {
                path: "/",
                expires: new Date(new Date().getTime() + 24 * 3600 * 1000)
            });
        }
    }

    onClose() {
        this.setState({
            open: false
        });
    }

    async showAd(sType, Url, eventId) {
        // window.location.href = Url;
        // window.open(Url + "&r1=download", 'newwindow'+ sType);
        // window.location.href = Url + "&r1=download";
        // console.log(navigator.userAgent);
        // 暂时去掉ios两板功能
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
            // Url = "";
        }
        if (Url !== "") {
            const url = Url + "&rr1=download";
            //const uuid = Buffer.from(url).toString('base64');
            const uuid = encodeURIComponent(Buffer.from(url).toString('base64'));
            console.log(uuid);
            // gameId=06ea3f8f-04fa-4b21-800d-3c2220f11eb2&attributionId=9&accessToken=aafb7f27-da9d-4198-a301-0dd8f73761d2&transactionId=e2815246-7db3-4a41-95e4-e14bb85ee2cf
            let newurl = `/gotoad-return-purchase/?gameId=${this.state.newgameId}&attributionId=${this.state.newattributionId}&accessToken=${this.state.newaccessToken}&transactionId=${this.state.newtransactionId}&uuid=${uuid}&r1=download`;
            if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
                newurl = `/gotoadios/?gameId=${this.state.newgameId}&attributionId=${this.state.newattributionId}&accessToken=${this.state.newaccessToken}&transactionId=${this.state.newtransactionId}&uuid=${uuid}&r1=download`;
            }
            console.log(newurl);
            window.location.href = newurl;
            // if (eventId)
            //     setAdItem(this.state.newaccessToken, eventId, sType);
        }
    }

    async componentDidMount() {
        window.addEventListener('resize', this.getDirection);
        loadButtonData();
        if (this.props.session) {
            this.state.newaccessToken = this.props.session.accessToken;
            this.state.newgameId = this.props.gameId;
            this.state.newtransactionId = this.props.transactionId;
            this.state.newattributionId = this.props.attributionId;
            if (this.props.location.search) {
                let { platform } = formatQuery(this.props.location.search);
                this.state.platform = platform;
            } else {
                let platform = cookie.load("_platform");
                this.state.platform = platform;
            }

            // console.log("cc");
            // console.log(this.props.state);
            let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);
            cookie.save("_showReturnBtn", "0", {
                path: "/",
                expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
            });
            cookie.save("_gameId", this.props.gameId, {
                path: "/",
                expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
            });
            cookie.save("_gameId", this.props.gameId, {
                path: "/",
                expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
            });
            cookie.save("_accessToken", this.props.session.accessToken, {
                path: "/",
                expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
            });
            cookie.save("_transactionId", this.props.transactionId, {
                path: "/",
                expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
            });
            cookie.save("_attributionId", this.props.attributionId, {
                path: "/",
                expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
            });
            if (this.props.location.search) {
                if ((this.state.platform) && (this.state.platform === "h5")) {
                    let operation = cookie.load("_platform_operation");
                    if ((this.props.location.search.indexOf("userId") !== -1)) {
                        cookie.save('_platform_operation', "login");
                    } else {
                        cookie.save('_platform_operation', "pay");
                    }
                    cookie.save("_platform", this.state.platform);
                } else {
                    cookie.save('_platform_operation', "unknown");
                    cookie.save("_platform", "unknown");
                }
            }
            // 获取延迟发币的订单
            const data = await KaisakuApi.getQueryTransaction(this.props.session.accessToken);
            if ((data) && (data.data) && (data.data.trans)) {
                if (data.data.trans.length > 0) {
                    let trans = [];
                    for (let j = 0; j < data.data.trans.length; j++) {
                        trans.push({ "transactionId": data.data.trans[j].transactionId });
                        if (j > 49) {
                            break;
                        }
                    }
                    cookie.save("_datatrans", JSON.stringify(trans), { path: "/", expires: inFifteenMinutes });
                    cookie.save("_datatransindex", 0, { path: "/", expires: inFifteenMinutes });
                    cookie.save("_datatranstransactionId", data.data.trans[0].transactionId, { path: "/", expires: inFifteenMinutes });
                    // console.log(data.data.trans[0].transactionId);
                    navigate(`/credit-purchase-result/?transactionId=showqueryorder`);
                } else {
                    // 获取cookie上次弹出时间，是否大于30分钟，是进行进行触发
                    let showNoodle = true;
                    const q = cookie.load("_q");
                    if (q) {
                        let { productId } = formatQuery(q);
                        if (productId) {
                            showNoodle = false;
                        }
                    }
                    if ((this.props.session) && (showNoodle === true)) {
                        const res = await KaisakuApi.getNoodleInfoV(this.props.gameId, this.props.session.accessToken);
                        if ((res.data.success) || ((res.data.loginData) && (res.data.loginData.showInfo))) {
                            if (res.data.success) {
                                if (res.data.cardtype) {
                                    res.data.index = res.data.cardtype;
                                }

                                if (!cookie.load("entr" + this.props.session.accessToken + "index" + res.data.index)) {
                                    let platform = cookie.load("_platform");
                                    if (platform) {
                                        navigate(`/sdk-ad-noodle-entr2/?showInfo=${res.data.loginData.showInfo}&monthCount=${res.data.loginData.monthCount}&allcount=${res.data.loginData.allcount}&url=${res.data.url}&index=${res.data.index}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&accessToken=${this.props.session.accessToken}`);
                                    }
                                }
                            } else {
                                navigate(`/sdk-ad-noodle-entr2only/?showInfo=${res.data.loginData.showInfo}&monthCount=${res.data.loginData.monthCount}&allcount=${res.data.loginData.allcount}&url=${res.data.url}&index=${res.data.index}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&accessToken=${this.props.session.accessToken}`);
                            }
                            // if (res.data.url) {
                            //     if (res.data.url.indexOf("sdk-ad-noodle-unionpay") === -1) {
                            //         if (res.data.url.indexOf("sdk-ad-noodle-nw") === -1) {
                            //             if (res.data.url.indexOf("sdk-ad-noodle-nu") === -1) {
                            //                 if (res.data.url.indexOf("sdk-ad-noodle-fr") === -1) {
                            //                     if (res.data.url.indexOf("sdk-ad-noodle-bc") === -1) {
                            //                         if (!cookie.load("vip" + this.props.session.accessToken + "index" + res.data.index)) {
                            //                             navigate(`/${res.data.url}/?index=${res.data.index}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&accessToken=${this.props.session.accessToken}`);
                            //                         }
                            //                     } else {
                            //                         if (!cookie.load("bc" + this.props.session.accessToken + "index" + res.data.index)) {
                            //                             navigate(`/${res.data.url}/?index=${res.data.index}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&accessToken=${this.props.session.accessToken}`);
                            //                         }
                            //                     }
                            //                 } else {
                            //                     if (!cookie.load("fr" + this.props.session.accessToken + "index" + res.data.index)) {
                            //                         navigate(`/${res.data.url}/?index=${res.data.index}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&accessToken=${this.props.session.accessToken}`);
                            //                     }
                            //                 }
                            //             } else {
                            //                 if (!cookie.load("nu" + this.props.session.accessToken + "index" + res.data.index)) {
                            //                     navigate(`/${res.data.url}/?index=${res.data.index}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&accessToken=${this.props.session.accessToken}`);
                            //                 }
                            //             }
                            //         } else {
                            //             console.log("cardtype = " + res.data.cardtype);
                            //             if (!cookie.load("card" + this.props.session.accessToken + "index" + res.data.cardtype)) {
                            //                 navigate(`/${res.data.url}/?index=${res.data.cardtype}&startTime=${res.data.startTime}&endTime=${res.data.endTime}&accessToken=${this.props.session.accessToken}`);
                            //             }
                            //         }
                            //     } else {
                            //         if (!cookie.load("unionpay" + this.props.session.accessToken)) {
                            //             navigate(`/${res.data.url}/?startTime=${res.data.startTime}&endTime=${res.data.endTime}&accessToken=${this.props.session.accessToken}`);
                            //         }
                            //     }
                            // }
                        }
                    }
                }
            }
        }
        let url = cookie.load("_action");
        if (url === undefined) {
            url = "";
        }
        const productId = cookie.load("_productId");
        // let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);
        // const _alipaytransactionId = cookie.load("_alipaytransactionId");
        // cookie.save("_alipaytransactionId", "", {
        //     path:"/",
        //     expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
        // });
        if ((url.indexOf("payment-providers") !== -1) && (productId !== undefined)) {
            let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);
            cookie.save("_action", "", {
                path: "/",
                expires: inFifteenMinutes // Will expire after 24hr from setting (value is in Date object)
            });
            const a = url.split("/payment-providers/");
            navigate(`/payment-providers/?productId=${productId}&currency=CNY`);
        }

        let isIOS = "0";
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
            // 暂时去掉ios两板功能
            isIOS = "1";
            const res = await getAdPItem(this.state.newaccessToken, isIOS);
            // console.log(res);
            this.state.eventId = res.eventId;
            if ((res.adItem1) && (res.adItem1.adItems)) {
                this.state.adItemTop = res.adItem1.adItems;
            }
            if ((res.adItem2) && (res.adItem2.adItems)) {
                this.state.adItem1 = res.adItem2.adItems;
            }
            this.state.isCnIP = res.isCnIP;
        }
        if (isIOS === "0") {
            const res = await getAdPItem(this.state.newaccessToken, isIOS);
            // console.log(res);
            this.state.eventId = res.eventId;
            if ((res.adItem1) && (res.adItem1.adItems)) {
                this.state.adItemTop = res.adItem1.adItems;
            }
            if ((res.adItem2) && (res.adItem2.adItems)) {
                this.state.adItem1 = res.adItem2.adItems;
            }
            this.state.isCnIP = res.isCnIP;
        }
        // 开启定时器
        this.resetQueryTimer();
        // setAdItem(this.state.userId, "", "P3", 1, "", -1, isIOS);
        // this.state.adItemTop.eventId = res.eventId;
        // this.state.adItemTop.adimg = res.adP1Image;    //adIndex(this.state.newgameId, "1", this.state.newaccessToken);
        // // if (res.adP1Url === "") res.adP1Url = "javascript:void(0)";
        // this.state.adItemTop.adlink = res.adP1Url
        // // console.log(this.state.adItemTop);
        // this.state.adItem1.eventId = res.eventId;
        // this.state.adItem1.adimg = res.adP2Image;     //adIndex(this.state.newgameId, "2", this.state.newaccessToken);
        // // if (res.adP2Url === "") res.adP2Url = "javascript:void(0)";
        // this.state.adItem1.adlink = res.adP2Url;
        // console.log(this.state.adItem1);
        this.setState({
            adItemTop: this.state.adItemTop,
            adItem1: this.state.adItem1,
            isIOS: isIOS
        });
    }

    componentWillUnmount() {
        // 关闭定时器
        this.closeQueryTimer();
        window.removeEventListener('resize', this.getDirection);
    }

    resetQueryTimer() {
        if (this.props.session) {
            this.state.expireTimer = setTimeout(async () => {
                clearInterval(this.state.expireTimer);
                //
                const show = showbox(this.props.gameId, this.state.isIOS);
                if (show === true) {
                    if (this.state.open === false) {
                        const data = await this.getInfoStatus(this.props.gameId, this.props.session.accessToken);
                        if (data.chatMessage > 0) {
                            // 弹出窗口
                            console.log(data.chatLinks.url);
                            this.setState({
                                open: true,
                                MessageUrl: data.chatLinks.url.replace("notitle=", "notitle=1")
                            });
                        }
                    }
                    this.resetQueryTimer();
                } else {
                    console.log("关闭刷新");
                }

            }, 5000);
        }
    }

    async getInfoStatus(gameId, accessToken) {
        const data = await KaisakuApi.getMessageStatus(gameId, accessToken);
        return data;
    }

    closeQueryTimer() {
        if (this.state.expireTimer) {
            clearInterval(this.state.expireTimer);
            this.state.expireTimer = undefined;
            console.log("取消定时器");
        }
    }

    async onCancel() {
        // console.log("123");
        let { cancelUrl, returnUrl } = formatQuery(this.props.location.search);
        const list = this.props.location.search.split("&returnUrl=");
        // console.log("123");
        if (list.length >= 2) {
            returnUrl = list[1].split("&")[0];
        }
        let cancelUrl1 = decodeURIComponent(cancelUrl);
        let returnUrl1 = decodeURIComponent(returnUrl);
        // console.log("122");
        // console.log(decodeURIComponent(cancelUrl1));
        if ((returnUrl1 !== undefined) && (returnUrl1 !== "undefined")) {
            // console.log("1223333");
            cookie.save('cancelUrl1', cancelUrl1);
            cookie.save('returnUrl1', returnUrl1);
        } else {
            // console.log("1224");
            cancelUrl1 = cookie.load('cancelUrl1');
            returnUrl1 = cookie.load('returnUrl1');
            // console.log(cancelUrl1);
        }
        // console.log("Cancelling");
        const transaction = this.props.transaction;
        if (transaction) {
            if ((transaction.total === 0) && (transaction.products[0].itemName.indexOf("拍面禮包") !== -1)) {
                const q = cookie.load("_q");
                if (q) {
                    let { gameId, userId, accessToken, nickname, createdAt, expireAt, expireAt_TTL } = formatQuery(q);
                    AndroidComm.sendSession({ gameId: gameId, userId: userId, accessToken: accessToken, nickname: nickname, createdAt: createdAt, expireAt: expireAt, expireAt_TTL: expireAt_TTL });
                    this.state.clickFlag = false;
                    return;
                }
            }
        }

        let issdkorapp = false;
        issdkorapp = getUA.includes("JGGSDKWebView");
        if ((issdkorapp) || (cancelUrl1 === undefined) || (cancelUrl1 === "undefined")) {
            AndroidComm.sendRawMessage({
                success: false,
                message: "user cancelled"
            });
        } else {
            if (this.state.returnUrl1.indexOf("windowclose") !== -1) {
                window.close();
            } else {
                window.location.href = this.state.returnUrl1;
            }
            // window.location.href = cancelUrl1;
        }
    }

    getDirection = () => {
        this.setState({ state: this.state });
    }

    render() {
        let l1 = "";
        let l2 = [];
        if (typeof navigator !== `undefined`) {
            l1 = navigator.language;
            l2 = navigator.languages;
        }
        console.log("language");
        console.log(l1);
        console.log("languages");
        console.log(l2);
        let iconJgg = icon;
        if (environmentMode === "SANDBOX") iconJgg = iconGg;
        // const returnUrl = `${process.env.returnWebUrl}`;
        let { cancelUrl, returnUrl } = formatQuery(this.props.location.search);
        const list = this.props.location.search.split("&returnUrl=");
        // console.log("123");
        if (list.length >= 2) {
            returnUrl = list[1].split("&")[0];
        }
        // console.log(returnUrl);
        let showTextAd = {
            display: ''
        };
        if (environmentMode === "SANDBOX") showTextAd = {
            display: 'none'
        };
        const mode = detectOrient();
        if (typeof window !== `undefined`) {
            var docEl = document.documentElement;
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            if (mode === "PORTRAIT") {
                docEl.style.fontSize = 14 * (clientWidth / 450) + 'px';
            } else {
                docEl.style.fontSize = 14 * (clientWidth / 900) + 'px';
            }
        }
        let panding1 = "0rem";
        if (mode === "PORTRAIT") {
            panding1 = "0rem";
        }
        return (
            <>
                <div style={{ paddingTop: "40px" }}>


                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingTop: `${panding1}`,
                        // maxHeight: "calc(100vh - 40px)",
                        marginTop: "40px"
                    }}>
                        {/* language： {l1} */}
                        <Grid textAlign='center' verticalAlign='top' style={{ margin: 0 }}>
                            <Grid.Column style={{ width: "100vw" }}>
                                {(mode === "PORTRAIT") && (<>
                                    {/* <AdPSwipetoslide adItems={this.state.adItemTop} eventId={this.state.eventId} adClass="P1" newgameId={this.props.gameId} newaccessToken={this.state.newaccessToken} newtransactionId={this.props.transactionId} newattributionId={this.props.attributionId}/> */}
                                    <NavigationBar isCnIP={this.state.isCnIP} mode={"PORTRAIT"} platform={this.state.platform} showLogo={1} showTitle={0} onBack={this.onCancel.bind(this)} accessToken={this.props.session.accessToken} />
                                    <ProductPurchaseForm cancelUrl1={cancelUrl} returnUrl1={returnUrl} mode={mode} />
                                    <AdPSwipetoslide adItems={this.state.adItem1} eventId={this.state.eventId} adClass="P2" newgameId={this.props.gameId} newaccessToken={this.state.newaccessToken} newtransactionId={this.props.transactionId} newattributionId={this.props.attributionId} />
                                </>)}
                                {(mode === "LANDSCAPE") && (
                                    <>
                                        <NavigationBar isCnIP={this.state.isCnIP} mode={"LANDSCAPE"} platform={this.state.platform} showLogo={1} showTitle={0} onBack={this.onCancel.bind(this)} accessToken={this.props.session.accessToken} />
                                        <ProductPurchaseForm cancelUrl1={cancelUrl} returnUrl1={returnUrl} mode={mode} userId={this.state.userId}
                                            newgameId={this.props.gameId} newaccessToken={this.state.newaccessToken} newtransactionId={this.props.transactionId} newattributionId={this.props.attributionId}
                                        />
                                    </>
                                )}

                            </Grid.Column>
                        </Grid>
                    </div>
                    <MsgModal MessageUrl={this.state.MessageUrl}
                        onClose={this.onClose.bind(this)} open={this.state.open} />
                </div></>
        );
    }
});

function loadButtonData() {
    KaisakuApi.getPaymentButtonData().then(res => {
        if (res.status === 200) {
            if (res.data.success) {
                console.log(res.data.data);
                const buttonData = JSON.stringify(res.data.data);
                sessionStorage.setItem("buttonData", buttonData);
            }
        }
    });
}

function detectOrient() {
    if (typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    } else {
        return "";
    }
}