import React, {Component} from "react";
import {Button, Dimmer, Header, Image, Loader, Modal} from "semantic-ui-react";
import iconClose from "../images/close.png";

export class MsgModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        };
    }
    componentWillUnmount() {
    }

    render() {
        return (
            <Modal
                size={"mini"}
                open={this.props.open}
                style={{width:'96%'}}
            >
                <div style={{width:"100%",padding:"0px",margin:"0px"}}>
                <div style={{height:'40px',textAlign:'left',fontSize:'20px',fontWeight:'bold',
                         borderBottom:'1px solid #D6D6D6',
                                opacity:'1'}}
                            >
                                <div style={{
                                    color:'#686868',
                                    height:'40px',
                                    float:"right", textAlign:"left",
                                    paddingTop:'10px',
                                    paddingRight:"10px",
                                    paddingLeft:"10px",
                                    borderLeft:"1px solid #D0D0D0"
                                }}
                                     onClick={() => {
                                        this.props.onClose();
                                     }}
                                >
                                    <Image style={{
                                        height: "22px",
                                        minHeight:"22px"
                                    }} centered
                                           src={iconClose}
                                    />

                                </div>
                                <div style={{position:"absolute",left:"0", top:"0", right:"40px" ,fontWeight:"normal",color:'#686868',float:"left", textAlign:"center",paddingLeft:"20px",paddingTop:'8px'}}>JGG</div>

                            </div>
                    < iframe wrapped size='medium'
                             src= {this.props.MessageUrl}
                             scrolling="no"
                             frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="no"
                             style={{width:"100%", height:"75vh",overflowY:"auto", borderRadius:'10px 10px 0px 0px'}}
                    />                    
                </div>
            </Modal>
        );
    }
}