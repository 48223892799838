import React, {Component} from "react";
import {CurrencyButtonBase} from "../../lib/currency-button-base";
import currencyBtn from "./images/currency-btn-usd.png";

export class CurrencyButtonUSD extends Component {
    render() {
        return (
            <CurrencyButtonBase src={currencyBtn} currency={"USD"}/>
        );
    }
}