const androidnosdkgameids = [
    "0b78cded-f7ef-4ba1-a32b-d22c1e001a97", // 樱花
    "06ea3f8f-04fa-4b21-800d-3c2220f11eb2", // 叫我状元爷
    "3c7538c3-2478-4e2a-af82-f49445e96c6f", // 中世纪
    "c3ac171d-f946-4904-ab7e-91da1652ff58", // 封神
    "d074e9aa-ab68-447f-9c69-ac6f04191cf3", // 三国h
    "97faa45e-795e-4590-bb8d-86a2f8473739", // 战灵师
    "7b1c204c-0d65-4fe6-8a1b-f677505d82ea", // 欲望城物语

    "bbec58a5-e7e1-4a19-9fd1-dc05e5b58f95", // 逆王
    "39d0058f-b242-4f65-95ad-0d35db529d11", // 黑道
    "c8e616f4-f0bf-47d5-86bd-decb404441ea", // 末世
    "ff8073f9-ad9b-4295-be99-293d606f0cdb", // 爱爱
    "2c5bb6a0-4ef0-619f-bb7d-9a0ef31b9ca4", // 官网
    "98b345ba-79b6-4209-88ff-87ba865ddf54", // 仙剑

    "bb20d5cb-2c09-4164-9665-95579d0752ba"  // 测试
];
const iosnosdkgameids = [];

export function showbox(gameId, isIOS){
    return false;
    // 不使用主动通知
    // if (isIOS === "1"){
    //     return true;
    // }
    
    // if (isIOS === "0"){
    //     if (androidnosdkgameids.includes(gameId)){
    //         return true;
    //     }       
    // }
    
}