import React, {Component} from "react";
import KaisakuUserApi from "../../../controllers/kaisaku-user-api";
import View from "./product-purchase-form-view";
import AndroidComm from "../../../controllers/utilities/unity-android-communication";
import {formatQuery} from "../../../controllers/utilities/string-utils";
import {ProductPurchaseFormNotReady} from "./product-purchase-form-not-ready";
import {canBuyWithJGG} from "./product-purchase-form-view-lib";
import {connect} from "react-redux";
import KaisakuApi from "../../../controllers/kaisaku-api";
import {getUA} from "react-device-detect";
import cookie from 'react-cookies';
import {navigate} from "../../../../.cache/gatsby-browser-entry";

class ProductPurchaseForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ready: false,
            eventMessage: "",
            cancelUrl1: "",
            returnUrl1: "",
            clickTime: 0,
            clickFlag: false,
            hasEnough: false,
            queryTimer: null
        };
        this.api = new KaisakuUserApi(this.props.session);
        // console.log("11111");
        // console.log(this.props);
        this.state.cancelUrl1 = decodeURIComponent(this.props.cancelUrl1);
        this.state.returnUrl1 = decodeURIComponent(this.props.returnUrl1);
        // console.log("122");
        // console.log(decodeURIComponent(this.state.cancelUrl1));
        if ((this.state.returnUrl1 !== undefined) && (this.state.returnUrl1 !== "undefined")){
            console.log("1223333");
            cookie.save('cancelUrl1', this.state.cancelUrl1);
            cookie.save('returnUrl1', this.state.returnUrl1);
        }else{
            // console.log("1224");
            this.state.cancelUrl1 = cookie.load('cancelUrl1');
            this.state.returnUrl1 = cookie.load('returnUrl1');
            // console.log(this.state.cancelUrl1);
        }
    }

    async componentDidMount() {
        console.log("1111");
        console.log(this.state.ready);
        await this.getTransaction();
    }

    async onReturn(){
        const timeSub = Date.now() - this.state.clickTime;
        if ((timeSub >= 2000) && (this.state.clickFlag === false)){
            this.state.clickFlag = true;
            this.state.clickTime = Date.now();
            const transaction = this.props.transaction;
            if(!transaction) {
                return;
            }

            if ((transaction.total === 0) && (transaction.products[0].itemName.indexOf("拍面禮包") !== -1)){
                const q = cookie.load("_q");
                if (q){
                    let {gameId, userId, accessToken, nickname, createdAt, expireAt, expireAt_TTL} = formatQuery(q);
                    AndroidComm.sendSession({ gameId:gameId, userId: userId, accessToken: accessToken,nickname: nickname,createdAt: createdAt, expireAt: expireAt, expireAt_TTL: expireAt_TTL });   
                    this.state.clickFlag = false;
                    return;
                }
            }

            let issdkorapp = false;
            issdkorapp = getUA.includes("JGGSDKWebView");
            if ((issdkorapp) || (this.state.cancelUrl1 === undefined) || (this.state.returnUrl1 === "undefined")){
                AndroidComm.sendRawMessage({
                    success: false,
                    message: "user returned"
                });
            }else{
                if (this.state.returnUrl1.indexOf("windowclose") !== -1){
                    window.close();
                }else{
                    window.location.href = this.state.returnUrl1;
                }
            }
            this.state.clickFlag = false;
        }else{
            console.log(timeSub);
        }
    }
    
    async resetQueryTimer(){
        clearInterval(this.queryTimer);
        this.setState({retryIn: 5});
        this.queryTimer = setInterval(async () => {
            let {retryIn} = this.state;
            console.log(--retryIn);
            this.setState({retryIn});
            if(retryIn <= 0) {
                clearInterval(this.queryTimer);
                await this.getTransaction();
                this.resetQueryTimer();
            }
        }, 1000);
    }

    async onUpTop(){
        this.resetQueryTimer(); 
       console.log("正在打開充值介面...");
       // console.log(this.props.session.accessToken);
       const { transactionId } = await this.api.createUpTop();
       // console.log(transactionId);
       const accessToken = this.props.session.accessToken;
       const url = "https://sdk.jggsdk.com/confirm-purchase/?gameId=aac53ce9-f03c-4e71-953b-df9ec022a837&transactionId=" + transactionId + "&attributionId=0&accessToken=" + accessToken + "&returnUrl=http%3A%2F%2Fjgg18.me%3Fr%3Dwindowclose";
       // console.log(url);
       // window.open(url, "newwindow");
       const data={
        "operation": "open_url",
        "params": {
            "url": url
         }
       };
       window.top.postMessage(data,'*');
    }

    async getTransaction(){
        const {transactionId} = this.props;
        if(transactionId) {
            console.log(transactionId);
            const {
                status, data, data: {
                    message
                }
            } = await this.api.getTransaction(transactionId);
        
            if(status === 200) {
                cookie.save('_buttonDataUID', data.userId);
                console.log(data.userId);
                this.setState({
                    success: true,
                    ready: true,
                    hasEnough: canBuyWithJGG(data),
                });
                this.props.setTransaction(data);
            } else {
                this.setState({
                    success: false,
                    ready: true,
                    message
                });
            }            

            // const events = await KaisakuApi.getActiveEvents();
            // if(events.length > 0) {
            //     this.setState({
            //         eventMessage: `${events[0].name}節日增值優惠`
            //     });
            // }
        }

    }

    async onBuy() {
        if (!this.state.hasEnough) {
            console.log("不夠幣");
            return;
        }
        const timeSub = Date.now() - this.state.clickTime;
        if ((timeSub >= 2000) && (this.state.clickFlag === false)){
            this.state.clickFlag = true;
            this.state.clickTime = Date.now();
            const transaction = this.props.transaction;
            if(!transaction) {
                return;
            }
            const res = await this.api.confirmTransaction(transaction.transactionId);
            let issdkorapp = false;
            issdkorapp = getUA.includes("JGGSDKWebView");
            if ((issdkorapp) || (this.state.cancelUrl1 === undefined) || (this.state.returnUrl1 === "undefined")){
                AndroidComm.sendPurchaseResult(res);
            }else{
                window.location.href = this.state.returnUrl1;
            }
            this.state.clickFlag = false;
        }else{
            console.log(timeSub);
        }

    }

    async onCancel() {
        console.log("Cancelling");
        let issdkorapp = false;
        issdkorapp = getUA.includes("JGGSDKWebView");
        if ((issdkorapp) || (this.state.cancelUrl1 === undefined) || (this.state.cancelUrl1 === "undefined")){
            AndroidComm.sendRawMessage({
                success: false,
                message: "user cancelled"
            });
        }else{
            window.location.href = this.state.cancelUrl1;
        }
    }

    async onLastReport() {
        navigate(`/myreportreturnconfirm`);
    }

    render() {
        const bonusMessage = this.state.hasEnough ? "玩家需自行點擊“確認交易”購買遊戲禮包，充值為平台幣，不是遊戲禮包。" : "平台幣餘額不足，請充值平台幣，再點擊“確認交易”購買遊戲禮包。";
        return (
            ((!!this.props.transaction) && (this.state.ready)) ?
                <View
                    eventMessage={this.state.eventMessage}
                    bonusMessage={bonusMessage}
                    transaction={this.props.transaction}
                    loading={!this.state.ready}
                    success={this.state.success}
                    hasEnough={this.state.hasEnough}
                    errorMsgs={[]}
                    onUpTop={this.onUpTop.bind(this)}
                    onBuy={this.onBuy.bind(this)}
                    onReturn={this.onReturn.bind(this)}
                    onCancel={this.onCancel.bind(this)}
                    onLastReport={this.onLastReport.bind(this)}
                    mode={this.props.mode}
                    newgameId={this.props.newgameId}
                    newattributionId={this.props.newattributionId}
                    newaccessToken={this.props.newaccessToken}
                    newtransactionId={this.props.newtransactionId}
                    userId={this.props.userId}
                />
                :
                <ProductPurchaseFormNotReady loading={!this.state.ready}
                                             success={this.state.success}
                                             error={!!this.state.message}
                                             message={this.state.message}/>
        );
    }
}

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings
    };
}, dispatch => {
    return {
        setTransaction: (transaction) => {
            dispatch({
                type: "SET_TRANSACTION",
                value: transaction
            });
        }
    }
})(ProductPurchaseForm);