import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImgVideoSmall, setAdItem } from "../lib/ad-manage-api-lib";

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "(255,255,255,0)", right: "0px", zIndex: 1000 }}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "(255,255,255,0)", left: "0px", zIndex: 1000 }}
            onClick={onClick}
        />
    );
}

export class AdPSwipetoslide extends Component {
    constructor(props, context) {
        super(props, context);
    }

    async showAd(sType, Url, index, imageUrl, click, id) {
        // window.location.href = Url;
        // window.open(Url + "&r1=download", 'newwindow'+ sType);
        // window.location.href = Url + "&r1=download";
        // console.log(navigator.userAgent);
        // 暂时去掉ios两板功能
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
            // Url = "";
        }
        if (Url !== "") {
            const url = Url + "&rr1=download";
            //const uuid = Buffer.from(url).toString('base64');
            const uuid = encodeURIComponent(Buffer.from(url).toString('base64'));
            console.log(uuid);
            // gameId=06ea3f8f-04fa-4b21-800d-3c2220f11eb2&attributionId=9&accessToken=aafb7f27-da9d-4198-a301-0dd8f73761d2&transactionId=e2815246-7db3-4a41-95e4-e14bb85ee2cf
            let newurl = `/gotoad-return-purchase/?gameId=${this.state.newgameId}&attributionId=${this.state.newattributionId}&accessToken=${this.state.newaccessToken}&transactionId=${this.state.newtransactionId}&uuid=${uuid}&r1=download`;
            if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
                newurl = `/gotoadios/?gameId=${this.state.newgameId}&attributionId=${this.state.newattributionId}&accessToken=${this.state.newaccessToken}&transactionId=${this.state.newtransactionId}&uuid=${uuid}&r1=download`;
                if (url.indexOf("ios.18-jgg.com") >= 0) {
                    newurl = url;
                }
            }
            console.log(newurl);
            if (this.state.eventId) {
                setAdItem(id, this.state.eventId, sType, index, imageUrl, click);
            }
            window.location.href = newurl;
        }
    }

    render() {

        // console.log("2222");
        // console.log(this.state.adItems);
        const { adItems, eventId, adClass, newgameId, newattributionId, newaccessToken, newtransactionId } = this.props;
        this.state = {
            eventId: eventId,
            newgameId: newgameId,
            newattributionId: newattributionId,
            newaccessToken: newaccessToken,
            newtransactionId: newtransactionId,
            adClass: adClass,
            adItems: [],
        };
        let showed = [];
        this.state.adItems = adItems;
        // console.log("111111");
        // console.log(this.state.adItems);
        const settings = {
            dots: false,
            infinite: true,
            arrows: true,
            speed: 1000,
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnHover: true,
            lazyLoad: "ondemand", // "progressive",
            // pauseOnFocus: true,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            afterChange: function (index) {
                if (adItems[index] !== undefined) {
                    const imageUrl = adItems[index].adImage;
                    const ipos = adItems[index].pos;
                    const id = adItems[index].id;
                    if (!showed.includes(id)) {
                        showed.push(id);
                        setAdItem(id, eventId, adClass, ipos, imageUrl);
                    } else {
                        console.log("showed = " + showed.indexOf(id));
                    }
                }
            }
        };
        return (
            <div style={{
                width: '100%',
                height:"110px"
            }}>

            </div>
        );
    }
}

